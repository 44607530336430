import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  georgiaHero,
  georgiaSeal,
  georgiaRestaurantTypes,
  georgiaIconTemplate,
  georgiaLargeFeatures,
  georgiaWhiteGlove,
  georgiaXL,
  georgiaTestimonialsData,
} from "../../../data/local/georgia-data";
import LocalHero from "../../../components/Hero/LocalHero";

import ogImage from "../../../images/global_assets/og-image.png";

const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../../components/ColoradoPortedComps/Rest")
);
const PartnerLogos = loadable(() =>
  import("../../../components/ColoradoPortedComps/PartnerLogos")
);
const XLArea = loadable(() =>
  import("../../../components/ColoradoPortedComps/XLArea")
);
const LocalSlider = loadable(() =>
  import("../../../components/ColoradoPortedComps/LocalSlider")
);

const whiteGloveImport = "caregiver.png";

const georgia = () => {
  return (
    <Layout isLanding phoneNumber="+16784368087" phoneText="(678) 436-8087">
      <SEO
        title="SpotOn | Georgia"
        description=" As the preferred technology provider of the Georgia Restaurant Association, SpotOn is empowering Georgia restaurants and small businesses with integrated point-of-sale systems and software, backed by in-person local support and fair pricing."
        image={`https://spoton.com/${ogImage}`}
      />
      <LocalHero sectionData={georgiaHero} seal={georgiaSeal} />
      {/* <Hero hideBanner sectionData={georgiaHero} circleBg={false} aligned />
      <RASeal seal={georgiaSeal} /> */}
      <PartnerLogos />
      <Restaurants data={georgiaIconTemplate} nav="#" />
      <LocalSlider nav="#" testimonialsData={georgiaTestimonialsData} />
      <LocalRestaurants data={georgiaRestaurantTypes} nav="#" />
      <LargeFeatures
        sectionData={georgiaLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea data={georgiaXL} nav="#" />
      <WhiteGlove
        sectionData={georgiaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default georgia;
